
  import Vue from 'vue';
  import * as api from '@/api';
  import TypeSelectorSingle from '@/components/TypeSelectorSingle.vue';

  export default Vue.extend({
    props: {
      value: {
        type: Object,
        required: true,
      },
    },
    components: { TypeSelectorSingle },
    data() {
      return {
        filterData: {
          name: '',
          type: null,
          brandId: null as string | null,
        },
        brandList: [] as api.Brand[],
        typeSelectorVisible: false,
        typeName: '',
      };
    },
    async created() {
      const res = await api.getBrandList({
        MaxResultCount: 999, // 后端没有获取全部的接口
      });
      if (!api.error(res)) {
        this.brandList = res.data.items;
      }
    },
    methods: {
      onTypeInput(type) {
        if (type) {
          this.filterData.type = type.id;
          this.typeName = type.fullTxt;
        } else {
          this.filterData.type = null;
          this.typeName = '';
        }
        this.typeSelectorVisible = false;
      },
      onSubmit() {
        this.$emit('input', { ...this.filterData });
      },
      onReset() {
        this.filterData = {
          name: '',
          type: null,
          brandId: null,
        };
        this.$emit('input', { ...this.filterData });
      },
    },
  });
