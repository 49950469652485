
  import Vue from 'vue';
  import ProductFilterSimple from '@/components/ProductFilterSimple.vue';
  import ProductSortSelector from '@/components/ProductSortSelector.vue';
  import * as api from '@/api';
  import AuditDialog from './AuditDialog.vue';

  export default Vue.extend({
    name: 'ProductAudit',
    components: { ProductFilterSimple, ProductSortSelector, AuditDialog },
    data() {
      return {
        productList: [] as api.Product[],
        selectedProducts: [] as api.Product[],
        unpassReasonVisible: false,
        auditDetailVisible: false,
        filterData: {
          name: '',
          brandId: null as null | number,
          type: null as null | number,
        },
        pagination: {
          totalCount: 0,
          currentPage: 1,
          pageSize: 10,
        },
        queryParams: {
          name: '',
          sorting: '0',
          status: 0,
          skipCount: 0,
          maxResultCount: 10, // 同pageSize
          type: null as number | null,
          brandId: '',
        },
        loading: false,
      };
    },
    watch: {
      pagination: {
        handler(pagination) {
          this.queryParams.skipCount = (pagination.currentPage - 1) * pagination.pageSize;
          this.queryParams.maxResultCount = pagination.pageSize;
        },
        deep: true,
      },
      filterData: {
        handler(data) {
          this.queryParams = { ...this.queryParams, ...data };
        },
      },
      'queryParams.sorting': function () {
        this.queryParams = { ...this.queryParams };
      },
      queryParams: {
        deep: true,
        async handler() {
          await this.fetchData();
        },
      },
    },
    async created() {
      this.$store.commit('breadcrumbList', [
        {
          name: '产品审核',
          path: 'audit',
        },
      ]);
      await this.fetchData();
    },
    methods: {
      async fetchData() {
        this.loading = true;
        const res = await api.getProductAuditList({
          name: this.queryParams.name,
          brandId: this.queryParams.brandId,
          type: this.queryParams.type,
          sorting: this.queryParams.sorting,
          skipCount: this.queryParams.skipCount,
          maxResultCount: this.queryParams.maxResultCount,
        });
        this.loading = false;
        if (!api.error(res)) {
          this.pagination.totalCount = res.data.totalCount;
          this.productList = res.data.items;
        }
      },
      async editProductPass(value: boolean, unpassReason?: string) {
        const productIds = this.selectedProducts.map((product) => product.productId);
        const res = value ? await api.putProductAuditPass({ productIds }) : await api.putProductAuditUnpass({ productIds, reason: unpassReason as string });
        if (!api.error(res)) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });
          this.unpassReasonVisible = false;
          await this.fetchData();
        } else {
          this.$message({
            type: 'error',
            message: res.error.message,
          });
        }
      },
      showProductDetail(product) {
        this.$store.commit('productInfoModule/currentProductInfo', {
          productId: product.productId,
          pvId: product.pvId,
          status: product.status,
          statusTxt: product.statusTxt,
          name: product.name,
        });
        this.$router.push({
          path: '/product/edit',
        });
      },
      handleSelectionChange(selection: api.Product[]) {
        this.selectedProducts = selection;
      },
      handleBatchCommand(command) {
        if (this.selectedProducts.length === 0) {
          this.$message({
            type: 'warning',
            message: '未选中任何产品',
          });
          return;
        }
        switch (command) {
          case 'pass':
            this.editProductPass(true);
            break;
          case 'unpass':
            this.unpassReasonVisible = true;
            break;
          default:
        }
      },
    },
  });
